<template>
    <div>
      <Breadcrumb :breadcrumbList="breadcrumbList" />
      <div id="timer-gps-add-content" class="content-header px-0">
        <h1 id="timer-gps-add-title" class="title-I">Adicionar Timer</h1>
      </div>
      <div id="timer-gps-add-div-master" class="col-12 col-sm-12 p-0 col-md-12">
        <div id="timer-gps-add-div-card" class="card direct-chat direct-chat-primary">
          <div id="timer-gps-add-card-body" class="card-body">
            <div id="timer-gps-add-form-wrapper" class="p-4 bg-white rounded">
              <form
                id="timer-gps-add-savecont-form"
                @submit="saveGPSTimers"
                novalidate="true"
              >
                <div id="timer-gps-add-wrapper-row" class="row">
                  <TwInput
                    id="readingGPSTW"
                    label="Timer de Leitura do GPS"
                    required="true"
                    col_lg="4"
                  >
                    <input
                      id="readingTime"
                      v-model="editedtimers.reading"
                      required
                      type="number"
                      class="form-control"
                      placeholder="45"
                    />
                  </TwInput>
                  <TwInput
                    id="sendingGPSTW"
                    label="Timer de Envio dos Dados do GPS"
                    required="true"
                    col_lg="4"
                  >
                    <input
                      id="sendingGPSTime"
                      v-model="editedtimers.sending"
                      required
                      type="number"
                      class="form-control"
                      placeholder="30"
                    />
                  </TwInput>
                </div>
                <div id="timer-gps-add-but-wrapper" class="row mt-2 p-1">
                  <button
                    id="saveGPSTimers"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i
                      id="timers-save"
                      v-if="load"
                      class="fas fa-sync-alt fa-spin"
                    ></i>
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Breadcrumb from "../../../components/Breadcrumb.vue";
  import TwInput from "@/components/TwInput.vue";
  export default {
    name: "TimersGPSTab",
    data: function () {
      return {
        breadcrumbList: [],
        editedtimers: {
          reading: "",
          sending: "",
          },
      };
    },
    mounted() {
      if (this.$route.params.customerId) {
        this.$store.dispatch("customer/getGPSCustomer", {
          customerId: this.$route.params.customerId,
        });
        this.$store.dispatch("customer/getGPSTimers", {
          customer_id: this.$route.params.customerId,
        });
      } else {
        window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
        this.$router.push("/");
      }
    },
    methods: {
      saveGPSTimers() {
        event.preventDefault();
        for (const key of Object.keys(this.editedtimers)) {
          if (this.editedtimers[key] <= 0 || this.editedtimers[key] == "") {
            window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
              field: "Timers",
              extra: "Todos os valores devem ser maiores que 0.",
            });
            return;
          }
          if (this.editedtimers[key] > 3600) {
            window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
              field: "Timers",
              extra: "Todos os valores devem ser menores que 3600.",
            });
            return;
          }
        }
        if (this.timersGPSExist) {
          this.$store.dispatch("customer/saveGPSTimers", {
            customer_id: this.$route.params.customerId,
            timers: {...this.editedtimers, id: this.timersGPS.id},
          });
        } else {
          this.$store.dispatch("customer/addGPSTimers", {
            customer_id: this.$route.params.customerId,
            timers: this.editedtimers,
          });
        }
      },
    },
    watch: {
      $route() {
        this.breadcrumbList = this.$route.meta.breadcrumb;
      },
      timersGPS() {
        delete this.timersGPS.customer_id;
        this.editedtimers = {...this.timersGPS};
      },
    },
    computed: {
      load() {
        return this.$store.getters["load"];
      },
      customer() {
        return this.$store.getters["customer/customer"];
      },
      timersGPS() {
        return this.$store.getters["customer/timersGPS"];
      },
      timersGPSExist() {
        return this.$store.getters["customer/timersGPSExist"];
      },
    },
    components: {
      Breadcrumb,
      TwInput,
    },
  };
  </script>