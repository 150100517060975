import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";
import tag from "./tag";

export default {
  namespaced: true,

  state: {
    tags: [],
    availableTags: [],
    customerTags: [],
    employeeTags: [],
    assetTags: [],
    tagSector: [],
    customerTagSectors: [],
    loadingCustomerTags: false,
    loadingAssign: false,
    doneRemovingTag: true,
  },

  mutations: {
    async GET_ALL_TAGS(state) {
      await api
        .get("tags?sortBy=tag_label/")
        .then((res) => {
          state.tags = res.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Tag", "GET_TAGS");
        });
    },

    async GET_TAGS_SECTOR(state, data) {
      await api
        .get(`customers/${data.customer_id}/tags/sector`)
        .then((res) => {
          state.customerTagSectors = res.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Tag", "GET_TAGS_SECTOR");
        });
    },
    async ADD_TAGS_SECTOR(state, data) {
      await api
        .post(`customers/${data.customer_id}/tags/sector`, {
          name: data.tag_sector_name,
        })
        .then((response) => {
          let tag_sector = response.data;
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Tag Sector", "ADD_TAG_SECTOR");
          state.customerTagSectors.push(tag_sector);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Tag Sector", "ADD_TAG_SECTOR");
        });
    },
    async DELETE_TAGS_SECTOR(state, data) {
      await api
        .delete(
          `customers/${data.customer_id}/tags/sector?name=${data.tag_sector_name}`
        )
        .then(() => {
          displayErrMsg(
            "GLOBAL_SUCCESS_DELETE",
            "Tag Sector",
            "DELETE_TAG_SECTOR"
          );
          state.customerTagSectors = state.customerTagSectors.filter(
            (sectors) => sectors.name !== data.tag_sector_name
          );
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Tag Sector",
            "DELETE_TAG_SECTOR"
          );
        });
    },

    SET_AVAILABLE_TAGS(state, tags) {
      state.availableTags = tags;
    },

    async GET_CUSTOMER_TAGS(state, { data }) {
      state.loadingCustomerTags = true;
      await api
        .get("tags/customer/" + data.customer_id)
        .then((res) => {
          state.customerTags = res.data;
          state.loadingCustomerTags = false;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Tag", "GET_CUSTOMER_TAGS");
        });
    },

    async GET_AVAILABLE_TAGS(state, { data }) {
      await api
        .get("tags/customer/-1")
        .then((res) => {
          state.availableTags = res.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Tag", "GET_AVAILABLE_TAGS");
        });
    },

    async GET_EMPLOYEE_TAGS(state, { data }) {
      await api
        .get("employees/" + data.employee_id)
        .then((res) => {
          let tags = [];
          if (res.data.tags) {
            res.data.tags.forEach(async (tag) => {
              // TODO: Check need for await/promise & catch
              await api.get("tags/" + tag.id).then((tagRes) => {
                tags.push(tagRes.data);
              });
            });
            state.employeeTags = tags;
          } else {
            state.employeeTags = [];
          }
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Tag", "GET_EMPLOYEE_TAGS");
        });
    },

    async ASSIGN_TAG_TO_EMPLOYEE(state, { data }) {
      this.state.loadingAssign = true;
      await api
        .patch("employees/" + data.employee_id + "/assign-mac", {
          mac_address: data.tag.mac_address,
        })
        .then(() => {
          displayErrMsg("ASSIGN_TAG_SUCCESS", "Tag", "ASSIGN_TAG_TO_EMPLOYEE");
          state.loadingAssign = false;
          state.employeeTags.push(data.tag);
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Tag",
            "ASSIGN_TAG_TO_EMPLOYEE"
          );
        });
    },

    async UNASSIGN_TAG_FROM_EMPLOYEE(state, { data }) {
      state.loadingCustomerTags = true;
      await api
        .patch("employees/" + data.employee_id + "/unassign-mac", {
          mac_address: data.tag.mac_address,
        })
        .then(() => {
          displayErrMsg(
            "UNASSIGN_TAG_SUCCESS",
            "Tag",
            "UNASSIGN_TAG_FROM_EMPLOYEE"
          );
          state.customerTags.forEach((tag) => {
            if (tag.mac_address === data.tag.mac_address) {
              tag.employee = null;
            }
          });
          state.employeeTags = state.employeeTags.filter(
            (tag) => tag.mac_address !== data.tag.mac_address
          );
          state.loadingCustomerTags = false;
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Tag",
            "UNASSIGN_TAG_FROM_EMPLOYEE"
          );
        });
    },
    async ASSIGN_SECTOR_TO_TAG(state, { data }) {
      await api
        .patch(`customers/${data.customerId}/tags/${data.tagId}/sector/assign`, {
          name: data.name,
        })
        .then(() => {
          displayErrMsg("ASSIGN_SECTOR_TO_TAG_SUCCESS", "Ativo", "ASSIGN_TAG");
          let left = state.customerTagSectors.filter((sector) => {
            return sector.name == data.name;
          });
          state.tagSector = left;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "ASSIGN_TAG");
        });
    },
    async UNASSIGN_SECTOR_FROM_TAG(state, { data }) {
      await api
        .patch(`customers/${data.customerId}/tags/${data.tagId}/sector/unassign`, {
          name: data.name,
        })
        .then(() => {
          displayErrMsg("UNASSIGN_SECTOR_TO_TAG_SUCCESS", "Ativo", "UNASSIGN_TAG");
          state.tagSector = state.tagSector.filter((sector) => {
            return sector.name !== data.name;
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "UNASSIGN_TAG");
        });
    },

    async ASSIGN_TAG_TO_CUSTOMER(state, { data }) {
      state.loadingAssign = false;
      const send_tag_list = data.tag_list.filter((id) => {
        return !state.customerTags.some((tag) => {
          return tag.id === id;
        });
      });
      if (send_tag_list.length) {
        await api
          .patch("tags/assign/" + data.customer_id, { id_list: send_tag_list })
          .then(() => {
            state.loadingAssign = false;

            state.availableTags.forEach((tag) => {
              if (data.tag_list.includes(tag.id)) {
                state.customerTags.push(tag);
              }
            });

            // remove tags from availableTags array if they have id in tag_list
            state.availableTags = state.availableTags.filter((tag) => {
              return !send_tag_list.some((id) => {
                return tag.id === id;
              });
            });
            state.tags.forEach((tag) => {
              if (data.tag_list.includes(tag.id)) {
                state.customerTags.push(tag);
              }
            });
            displayErrMsg(
              "ASSIGN_TAG_SUCCESS",
              "Tag",
              "ASSIGN_TAG_TO_CUSTOMER"
            );
          })
          .catch((err) => {
            displayErrMsg(
              err.response.data.code,
              "Tag",
              "ASSIGN_TAG_TO_CUSTOMER"
            );
          });
      } else {
        displayErrMsg("ASSIGN_TAG_DUPED", "Tag", "ASSIGN_TAG_TO_CUSTOMER");
      }
    },

    async UNASSIGN_TAG_FROM_CUSTOMER(state, { data }) {
      state.doneRemovingTag = false;
      const send_tag_list = data.tag_list.filter((id) => {
        return state.customerTags.some((tag) => {
          return tag.id === id;
        });
      });
      await api
        .patch("tags/unassign/" + data.customer_id, { id_list: send_tag_list })
        .then(() => {
          displayErrMsg(
            "UNASSIGN_TAG_SUCCESS",
            "Tag",
            "UNASSIGN_TAG_FROM_CUSTOMER"
          );
          // push to availableTags array all tags that have id in tag_list
          send_tag_list.forEach((id) => {
            state.availableTags.push(
              state.customerTags.find((tag) => {
                return tag.id === id;
              })
            );
          });
          state.customerTags = state.customerTags.filter((tag) => {
            return !data.tag_list.includes(tag.id);
          });
          state.doneRemovingTag = true;
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Tag",
            "UNASSIGN_TAG_FROM_CUSTOMER"
          );
        });
    },
    async ASSIGN_TAG_TO_ASSET(state, { data }) {
      await api
        .patch("assets/" + data.assetId + "/assign-mac", {
          mac_address: data.mac_address,
        })
        .then(() => {
          displayErrMsg("ASSIGN_TAG_SUCCESS", "Ativo", "ASSIGN_TAG");

          let left = state.customerTags.filter((tag) => {
            return tag.asset && tag.asset.id == data.assetId;
          });
          state.assetTags = left;
          state.customerTags = state.customerTags.filter((tag) => {
            if (tag.mac_address == data.mac_address) {
              tag.asset = { id: data.assetId };
              state.assetTags.push(tag);
            }
            return true;
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "ASSIGN_TAG");
        });
    },
    async UNASSIGN_TAG_FROM_ASSET(state, { data }) {
      await api
        .patch("assets/" + data.assetId + "/unassign-mac", {
          mac_address: data.mac_address,
        })
        .then(() => {
          displayErrMsg("UNASSIGN_TAG_SUCCESS", "Ativo", "UNASSIGN_TAG");
          state.assetTags = state.assetTags.filter((tag) => {
            return tag.mac_address !== data.mac_address;
          });
          state.customerTags.forEach((tag) => {
            if (tag.mac_address === data.mac_address) {
              tag.asset = null;
            }
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Ativo", "UNASSIGN_TAG");
        });
    },

    SET_ASSET_TAGS(state, data) {
      state.assetTags = data;
    },
  },
  getters: {
    tags: (state) => state.tags,
    assetTags: (state) => state.assetTags,
    availableTags: (state) => state.availableTags,
    customerTags: (state) => state.customerTags,
    customerTagSectors: (state) => state.customerTagSectors,
    employeeTags: (state) => state.employeeTags,
    loadingCustomerTags: (state) => state.loadingCustomerTags,
    loadingAssign: (state) => state.loadingAssign,
    tagSector: (state) => state.tagSector,
  },
  actions: {
    getAllTags(context, data) {
      context.commit("GET_ALL_TAGS");
    },
    getSectorTags(context, data){
      context.commit("GET_TAGS_SECTOR", data);
    },
    addTagsSector(context, data) {
      context.commit("ADD_TAGS_SECTOR", data);
    },
    deleteTagsSector(context, data) {
      context.commit("DELETE_TAGS_SECTOR", data);
    },
    setAvailableTags(context, data) {
      context.commit("SET_AVAILABLE_TAGS", data);
    },
    getEmployeeTags(context, data) {
      context.commit("GET_EMPLOYEE_TAGS", { data });
    },
    getCustomerTags(context, data) {
      context.commit("GET_CUSTOMER_TAGS", { data });
    },
    assignTagToEmployee(context, data) {
      context.commit("ASSIGN_TAG_TO_EMPLOYEE", { data });
    },
    unassignTagFromEmployee(context, data) {
      context.commit("UNASSIGN_TAG_FROM_EMPLOYEE", { data });
    },
    assignSectorToTag(context, data) {
      context.commit("ASSIGN_SECTOR_TO_TAG", { data });
    },
    unassignSectorFromTag(context, data) {
      context.commit("UNASSIGN_SECTOR_FROM_TAG", { data });
    },
    assignTagToCustomer(context, data) {
      context.commit("ASSIGN_TAG_TO_CUSTOMER", { data });
    },
    unassignTagFromCustomer(context, data) {
      context.commit("UNASSIGN_TAG_FROM_CUSTOMER", { data });
    },

    getAvailableTags(context, data) {
      context.commit("GET_AVAILABLE_TAGS", { data });
    },
    async unassignTagsFromCustomer(context, { customer_id, tags }) {
      const ids = tags.map((tag) => {
        return tag.id;
      });
      try {
        await api.patch("tags/unassign/" + customer_id, { id_list: ids });
        const data = {
          customer_id,
        };
        context.commit("GET_CUSTOMER_TAGS", { data });
        displayErrMsg(
          "UNASSIGN_TAG_SUCCESS",
          "Tag",
          "UNASSIGN_TAG_FROM_CUSTOMER"
        );
      } catch (err) {
        displayErrMsg(
          err.response.data.code,
          "Tag",
          "UNASSIGN_TAG_FROM_CUSTOMER"
        );
      }
    },

    assignTagtoAsset(context, data) {
      context.commit("ASSIGN_TAG_TO_ASSET", { data });
    },
    async unassignTagFromAsset(context, data) {
      await context.commit("UNASSIGN_TAG_FROM_ASSET", { data });
      await context.commit("GET_CUSTOMER_TAGS", { data });
    },
    setAssetTags(context, data) {
      context.commit("SET_ASSET_TAGS", data);
    },
  },
};
