import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    dashboards: [],
    reports: [],
    pbiTokenData: {},
    groups: [],
    workgroups: [],
  },
  mutations: {
    SET_DASHBOARDS(state, data) {
      //console.log(state);
      state.dashboards = data;
    },
    SET_REPORTS(state, data) {
      state.reports = data;
    },
    SET_PBI_TOKEN_DATA(state, data) {
      state.pbiTokenData = data;
    },
    SET_GROUPS(state, data) {
      state.groups = data;
    },
    SET_WORKGROUPS(state, data) {
      state.workgroups = data;
    },
    async REVOKE_EMBED_TOKEN(state, data) {
      await api
        .put(`dashboards/pbi/embed/token/revoke?group=${data.group}`)
        .then(() => {
          state.pbiTokenData = null;
          displayErrMsg("REVOKE_EMBED_TOKEN", 'Embed Token', "REVOKE_EMBED_TOKEN");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Embed Token', "REVOKE_EMBED_TOKEN");
        })
    }
  },
  getters: {
    dashboards: (state) => state.dashboards,
    reports: (state) => state.reports,
    pbiTokenData: (state) => state.pbiTokenData,
    groups: (state) => state.groups,
    workgroups: (state) => state.workgroups,
  },
  actions: {
    async getPBITokenData(context, data) {
      return await api
        .get(
          `/users/${data.email}/dashboards/pbi/embed/token?workgroup_id=${data.workgroup_id}&type=${data.type.toUpperCase()}&id=${data.id}`
        )
        .then((response) => {
          context.commit("SET_PBI_TOKEN_DATA", response.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Dashboard", "GET_PBI_TOKEN");
        });
    },
    getPBIItems(context, data) {
      api.get(`/users/${data.email}/dashboards/pbi`).then((response) => {
        context.commit("SET_DASHBOARDS", response.data["dashboards"]);
        context.commit("SET_REPORTS", response.data["reports"]);
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, "Dashboard", "GET_PBI_ITEMS");
      });
    },
    getGroupsWorkgroups(context, data) {
      let groups = [];
      let workgroups = [];
      api.get(`/users/${data.email}/dashboards/pbi/groups`).then((response) => {
        for (let item of response.data) {
          if (!groups.includes(item.group)) groups.push(item.group);
          if (
            !workgroups.includes({
              name: item.workgroup,
              id: item.workgroup_id,
              group: item.group,
            })
          )
            workgroups.push({
              name: item.workgroup,
              id: item.workgroup_id,
              group: item.group,
            });
        }
        context.commit("SET_GROUPS", groups);
        context.commit("SET_WORKGROUPS", workgroups);
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, "Dashboard", "GET_GROUPS_WORKGROUPS");
      });
    },
    revokeEmbedToken(context, data){
      context.commit("REVOKE_EMBED_TOKEN", data);
    }
  },
};
