<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="col-12 col-sm-12 p-0 col-md-12 row">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <h3 class="title-s" id="stats-gateway-name">
              Status do Coletor {{ gateway_name }}
            </h3>
            <div class="row enclose">
              <div
                class="nav-tabs col-12"
                style="font-size: 22px; margin-bottom: 15px"
              >
                Hardware
              </div>
              <div class="col-11 row-10" style="padding: 10px">
                <li>Espaço em disco:</li>
                <b-progress
                  :max="disk_total"
                  :value="disk_used"
                  :variant="disk_variant"
                  :animated="true"
                  style="width: 100%"
                  height="1rem"
                  id="disk-progress-bar"
                ></b-progress>
                <p id="stats-gateway-label-disk-used" style="text-align: end">
                  {{ disk_used }}/{{ disk_total }} GB
                </p>
                <li>Integridade do Disco:</li>
                <b-progress
                  :max="health_total"
                  :value="disk_health > 0 ? disk_health : 0"
                  :variant="disk_health_variant"
                  :animated="true"
                  style="width: 100%"
                  height="1rem"
                  id="health-progress-bar"
                ></b-progress>
                <p id="stats-gateway-label-disk-health" style="text-align: end">
                  {{ disk_health !== -1 ? disk_health : "N/A" }}{{ disk_health !== -1 ? "/" + health_total + "%" : ""}}
                </p>
                <li>Memória RAM:</li>
                <b-progress
                  :max="mem_total"
                  :value="mem_used"
                  :variant="mem_variant"
                  :animated="true"
                  style="width: 100%"
                  height="1rem"
                  id="mem-progress-bar"
                ></b-progress>
                <p id="stats-gateway-label-mem-used" style="text-align: end">
                  {{ mem_used }}/{{ mem_total }} MB
                </p>
              </div>

              <div class="col-12 row-10 cpu_perso">CPU</div>
              <div class="col-12 row">
                <div
                  class="col-4"
                  style="display: flex; justify-content: center"
                >
                  <div>
                    <p>1 minuto</p>
                    <div
                      class="cp-1min"
                      style="width: 100%"
                      height="10rem"
                      id="1min-circle-progress"
                    ></div>
                  </div>
                </div>
                <div
                  class="col-4"
                  style="display: flex; justify-content: center"
                >
                  <div>
                    <p>5 minutos</p>
                    <div
                      class="cp-5min"
                      style="width: 100%"
                      height="10rem"
                      id="5min-circle-progress"
                    ></div>
                  </div>
                </div>
                <div
                  class="col-4"
                  style="display: flex; justify-content: center"
                >
                  <div>
                    <p>15 minutos</p>
                    <div
                      class="cp-15min"
                      style="width: 100%"
                      height="10rem"
                      id="15min-circle-progress"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="col-12 row" style="padding-top: 50px">
                <div
                  class="col-4"
                  style="display: flex; justify-content: center"
                >
                  <div style="display: flex; flex-direction: column">
                    <p>Temperatura:</p>
                    <b-progress
                      :max="120"
                      :value="temp"
                      :variant="temp_variant"
                      :animated="true"
                      class="horizontal-bar"
                      id="temp-progress-bar"
                    ></b-progress>
                    <p
                      id="stats-gateway-temp"
                      style="text-align: center; margin-top: 50px"
                    >
                      {{ temp }} ºC
                    </p>
                  </div>
                </div>
                <div
                  class="col-4"
                  style="display: flex; justify-content: center"
                >
                  <div
                    style="display: flex; flex-direction: column"
                  >
                    <p>Velocidade da FAN:</p>
                    <b-progress
                      :max="10000"
                      :value="cpu_fan_speed > 0 ? cpu_fan_speed : 0"
                      :variant="cpu_fan_speed_variant"
                      :animated="true"
                      class="horizontal-bar"
                      id="cpu-fan-speed-progress-bar"
                    ></b-progress>
                    <p
                      id="stats-gateway-cpu-fan-speed"
                      style="text-align: center; margin-top: 50px"
                          >
                    {{ cpu_fan_speed !== -1 ? cpu_fan_speed + ' RPM' : 'N/A' }}
                  </p>
                  </div>
                </div>
                <div
                  class="col-4"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div>
                    <li id="stats-gateway-label-disconnected">Perda de conexão: {{ disconnected }}</li>
                    <li id="stats-gateway-label-undervoltage">Undervoltage: {{ undervoltage }}</li>
                  </div>
                </div>
              </div>

              <div
                class="nav-tabs col-12"
                style="font-size: 22px; margin-bottom: 15px"
              >
                Estado
              </div>
              <div class="row col-6">
                <div class="col-12 row" style="padding: 10px">
                  <i
                    v-if="administrative_state === 'IN-SERVICE'"
                    class="fa fas fa-lg fa-check-circle iconspace"
                    title="In-service"
                  ></i>
                  <i
                    v-else-if="administrative_state === 'OUT-OF-SERVICE'"
                    class="fa fas fa-lg fa-ban iconspace"
                    title="Out-service"
                  ></i>
                  <div v-else class="yellow-circle" style="margin-top: -12px">
                    <i
                      class="fa fas fa-lg fa-shuffle iconspace"
                      style="color: white"
                      title="Reacheable"
                    ></i>
                  </div>
                  <p
                    id="stats-gateway-adm-state"
                    style="margin-left: 10px; margin-top: -12px"
                  >
                    {{ administrative_state }}
                  </p>
                </div>
                <div class="col-12 row" style="padding: 10px" v-if="administrative_state !== 'REACHEABLE'">
                  <i
                    v-if="ico_oper"
                    class="fas fa-lg fa-signal iconspace"
                    title="Online"
                  ></i>
                  <i
                    v-if="!ico_oper"
                    class="fas fa-lg fa-times-circle iconspace"
                    title="Offline"
                  ></i>
                  <p
                    id="stats-gateway-adm-state"
                    style="margin-left: 10px; margin-top: -12px"
                  >
                    {{ operational_state }}
                  </p>
                </div>

                <div class="row" style="padding: 0px">
                  <i
                    class="fas fa-lg fa-arrow-alt-circle-right"
                    title="Ip-Address"
                  ></i>
                  <p style="margin-top: -2px">
                    Endereço IP para acesso remoto:
                  </p>
                  <p
                    id="stats-gateway-first-ip"
                    style="margin-left: 10px; margin-top: -1px"
                  >
                    {{ vpn_ip_1 }}
                  </p>
                </div>
              </div>
              <div class="col-6" style="line-height: 60px">
                <div style="margin-top: -20px">
                  Primeira alteração:
                  <span id="stats-gateway-first-state-change">
                    {{ first_state_change }}
                  </span>
                </div>

                <div>
                  Última alteração:
                  <span id="stats-gateway-last-state-change">
                    {{ last_state_change }}
                  </span>
                </div>

                <div>
                  Total de oscilações ({{ getTodayDate() }}):
                  <span id="stats-gateway-changes-of-state" style="margin-left: 10px">
                    {{ changes_of_state }}
                  </span>
                </div>
              </div>  
              <div class="row col-12">
                <div class="row col-6" style="padding: 2px">
                  <div class="col-6 row">
                    <p style="margin-left: 10px" id="stats-gateway-uptime">
                      {{ `Tempo de inatividade: ${period_offline}` }}
                    </p>
                  </div>
                  <div class="col-10 row">
                    <p style="margin-left: 10px" id="stats-gateway-uptime">
                      {{
                        `Tempo total de inatividade (${getTodayDate()}): ${period_offline_today}`
                      }}
                    </p>
                  </div>
                  <div class="col-10 row">
                    <p style="margin-left: 10px" id="stats-gateway-uptime">
                      {{ `Tempo online: ${uptime}` }}
                    </p>
                  </div>
                  <div class="col-10 row">
                    <p
                      id="stats-gateway-last-seen-time"
                      style="margin-left: 10px"
                    >
                      Dados atualizados dia {{ last_seen_date }} às
                      {{ last_seen_time }}.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="nav-tabs col-12"
                style="font-size: 22px; margin-bottom: 15px"
              >
                Dispositivos do Coletor
              </div>
              <div class="devices-container">
                <div
                  v-for="device in gateway_devices"
                  :key="device.interface"
                  class="device-column"
                >
                  <div>
                    <div>
                      <i
                        :class="`fas ${getStateClass(device.state)}`"
                        style="margin-right: 3px"
                        :id="`stats-gateway-state-${device.device}`"
                      ></i>
                      Estado: {{ device.state }}
                      <li :id="`stats-gateway-interface-${device.device}`">Interface: {{ device.interface }}</li>
                      <li :id="`stats-gateway-device-${device.device}`">Antena: {{ device.device }}</li>
                      <li :id="`stats-gateway-failure-${device.device}`">Falhas: {{ device.failure }}</li>
                      <li :id="`stats-gateway-inactivity-${device.device}`">Inatividade: {{ device.inactivity }}</li>
                      <li :id="`stats-gateway-last-seen-${device.device}`">
                          Último sinal:
                            {{ device.last_seen.split(" ")[0] }}
                            <br/>
                            <div style="padding-left: 120px">{{ device.last_seen.split(" ")[1] }}</div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import CircleProgress from "js-circle-progress";
export default {
  name: "AddGateway",
  data: function () {
    return {
      breadcrumbList: [],
      gateway: {
        gateway_model: "",
        gateway_name: "",
        gateway_type: "",
        mac_address: "",
        serial_number: "",
      },
      cpu_fan_speed: 0,
      disconnected: 0,
      undervoltage: 0,
      disk_health: 100,

      gateway_devices: [
        {
          interface: "",
          device: "",
          state: "",
          failure: "",
          inactivity: "",
          last_seen: "",
        },
      ],

      gateway_name: "",
      mem_used: "",
      vpn_ip_1: "",
      vpn_ip_2: "",
      uptime: "",
      temp: "",
      operational_state: "",
      changes_of_state: 0,
      period_offline: 0,
      period_offline_today: 0,
      mem_total: "",
      mem_variant: "success",
      temp_variant: "success",
      last_state_change: "",
      last_seen_time: "",
      last_seen_date: "",
      first_state_change: "",
      disk_used: "",
      disk_total: "",
      cpu_usage_15min: "",
      cpu_usage_5min: "",
      cpu_usage_1min: "",
      administrative_state: "",
      ico_oper: true,
      cp1min: "",
      cp5min: "",
      cp15min: "",
      color_percs: [],
      health_total: 100,
      health_variant: "success",
    };
  },
  created() {
    this.updateList();
    let statuscache = [];
    if (!this.$route.params.gateway) {
      statuscache = JSON.parse(localStorage.getItem("gatewaystatusdata"));
      if (!statuscache) {
        window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Coletor" });
        this.$router.push("/gateway");
      } else {
        this.gateway = statuscache;
      }
    } else {
      // Save the data to localstorage
      statuscache = this.$route.params.gateway;
      localStorage.setItem("gatewaystatusdata", JSON.stringify(statuscache));
      this.gateway = statuscache;
    }
    this.gateway_name = this.gateway.gateway_name;
    let mac_address = this.gateway.mac_address.replace(/:|-/g, "");
    this.$store.dispatch("gateway/getStatusGateway", {
      mac_address: mac_address,
    });
  },
  mounted() {
    this.cp1min = new CircleProgress(".cp-1min", {
      value: 0,
      max: 100,
      textFormat: "percent",
    });
    this.cp5min = new CircleProgress(".cp-5min", {
      value: 0,
      max: 100,
      textFormat: "percent",
    });
    this.cp15min = new CircleProgress(".cp-15min", {
      value: 0,
      max: 100,
      textFormat: "percent",
    });
  },
  watch: {
    $route() {
      this.updateList();
    },
    gatewayStatus() {
      this.cpu_fan_speed = this.gatewayStatus.gateway_state.cpu_fan_speed;
      this.disconnected = this.gatewayStatus.gateway_state.disconnected || 0;
      this.undervoltage = this.gatewayStatus.gateway_state.undervoltage || 0;
      this.disk_health = this.gatewayStatus.gateway_state.disk_health;

      this.gateway_devices = this.gatewayStatus.gateway_devices || [
        {
          interface: "",
          device: "",
          state: "",
          failure: null,
          inactivity: null,
          last_seen: "",
        },
      ];
      this.mem_used = this.gatewayStatus.gateway_state.mem_used;
      this.vpn_ip_1 = this.gatewayStatus.gateway_state.vpn_ip_1;
      this.vpn_ip_2 = this.gatewayStatus.gateway_state.vpn_ip_2;
      this.uptime = this.convert(this.gatewayStatus.gateway_state.uptime);
      this.changes_of_state = this.gatewayStatus.gateway_state.changes_of_state;

      this.period_offline = this.gatewayStatus.gateway_state.period_offline
        ? this.convert(this.gatewayStatus.gateway_state.period_offline)
        : "N/A";
      this.period_offline_today = this.gatewayStatus.gateway_state
        .period_offline_today
        ? this.convert(this.gatewayStatus.gateway_state.period_offline_today)
        : "N/A";
      this.temp = this.gatewayStatus.gateway_state.temp;
      this.operational_state =
        this.gatewayStatus.gateway_state.operational_state;
      this.mem_total = this.gatewayStatus.gateway_state.mem_total;
      this.last_state_change =
        this.gatewayStatus.gateway_state.last_state_change;
      this.last_seen_time = this.gatewayStatus.gateway_state.last_seen_time;
      this.last_seen_date = this.gatewayStatus.gateway_state.last_seen_date;
      this.first_state_change =
        this.gatewayStatus.gateway_state.first_state_change;
      this.disk_used = this.gatewayStatus.gateway_state.disk_used;
      this.disk_total = this.gatewayStatus.gateway_state.disk_total;
      this.cpu_usage_15min = this.gatewayStatus.gateway_state.cpu_usage_15min;
      this.cpu_usage_5min = this.gatewayStatus.gateway_state.cpu_usage_5min;
      this.cpu_usage_1min = this.gatewayStatus.gateway_state.cpu_usage_1min;
      this.cp1min.value = this.gatewayStatus.gateway_state.cpu_usage_1min;
      this.cp5min.value = this.gatewayStatus.gateway_state.cpu_usage_5min;
      this.cp15min.value = this.gatewayStatus.gateway_state.cpu_usage_15min;
      this.administrative_state =
        this.gatewayStatus.gateway_state.administrative_state;
      let variants = ["success", "warning", "danger"];
      let perc_colors = ["#28a745", "#ffc107", "#dc3545"];
      let disk_perc = this.disk_used / this.disk_total;
      let mem_perc = this.mem_used / this.mem_total;
      this.color_percs.push(
        perc_colors[this.map_perc(this.cp1min.value / 100)]
      );
      this.color_percs.push(
        perc_colors[this.map_perc(this.cp5min.value / 100)]
      );
      this.color_percs.push(
        perc_colors[this.map_perc(this.cp15min.value / 100)]
      );
      this.disk_variant = variants[this.map_perc(disk_perc)];
      this.mem_variant = variants[this.map_perc(mem_perc)];
      let temp_variant_index = this.temp < 60 ? 0 : this.temp < 80 ? 1 : 2;
      this.temp_variant = variants[temp_variant_index];
      this.ico_oper = this.operational_state === "ONLINE";
      let circs_prod = document.getElementsByClassName("circle-progress-value");
      for (let i = 0; i < 3; i++)
        circs_prod[i].style.stroke = this.color_percs[i];
    },
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    map_perc(perc) {
      return perc < 1 / 3 ? 0 : perc < 2 / 3 ? 1 : 2;
    },
    convert(seconds) {
      let days = Math.trunc(seconds / 86400);
      seconds -= days * 86400;
      let hours = Math.trunc(seconds / 3600);
      seconds -= hours * 3600;
      let minutes = Math.trunc(seconds / 60);
      seconds -= minutes * 60;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return (
        days +
        " dia(s) " +
        hours +
        " hora(s) " +
        minutes +
        " minuto(s) e " +
        seconds +
        " segundo(s)"
      );
    },
    getTodayDate() {
      const todayDate = new Date();
      const date =
        todayDate.getDate() < 10
          ? `0${todayDate.getDate()}`
          : todayDate.getDate();
      const month =
        todayDate.getMonth() < 9
          ? `0${todayDate.getMonth() + 1}`
          : todayDate.getMonth() + 1;
      return `${date}/${month}/${todayDate.getFullYear()}`;
    },
    getStatusClass(state) {
      if (state === "ACTIVE") return "status-active";
      if (state === "IDLE") return "status-idle";
      if (state === "ERROR") return "status-error";
      return "status-default";
    },

    getStateClass(state) {
      switch (state) {
        case "ACTIVE":
          return "fa-check-circle text-success"; 
        case "FAILED":
          return "fa-times-circle text-danger"; 
        case "IDLE":
          return "fa-pause-circle text-warning"; 
        case "INACTIVE":
          return "fa-ban text-secondary"; 
        case "NA":
          return "fa-question-circle text-muted"; 
        case "RESTART":
          return "fa-sync-alt text-info"; 
        default:
          return "fa-question-circle text-secondary";
      }
    },
  },
  components: {
    Breadcrumb,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    models() {
      return this.$store.getters["gateway/models"];
    },
    types() {
      return this.$store.getters["gateway/types"];
    },
    gatewayStatus() {
      return this.$store.getters["gateway/gatewayStatus"];
    },
    cpu_fan_speed_variant() {
      if (this.cpu_fan_speed <= 3400) {
        return "success"; 
      } else if (this.cpu_fan_speed <= 6800) {
        return "warning"; 
      } else {
        return "danger"; 
      }
    },
    disk_health_variant() {
      if (this.disk_health <= 50) {
        return "success"; 
      } else if (this.disk_health <= 80) {
        return "warning"; 
      } else {
        return "danger"; 
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.add-user {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.fa-check-circle {
  color: rgb(105, 245, 11);
}

.fa-ban {
  color: rgb(238, 0, 0);
}

.fa-signal {
  color: rgb(105, 245, 11);
}

.fa-times-circle {
  color: rgb(238, 0, 0);
}

.fa-arrow-alt-circle-right {
  padding: 10px;
  color: #000000;
}

.horizontal-bar {
  height: 2rem;
  width: 100px;
  transform: rotate(-90deg);
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.title-s {
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
  font-weight: bold;
}

.cpu_perso {
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
}

.circle_title {
  padding: 10px;
  text-align: center;
}

.enclose {
  margin: 45px;
  padding: 10px;
  font-size: 19px;
}
</style>
<style>
.devices-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.device-column {
  flex: 1 1 21%;
  padding: 10px;
  border-radius: 8px;
}

.extra-info {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.info-column {
  flex: 1;
}

.circle-progress-value {
  stroke-width: 6px;
  stroke-linecap: round;
}

.circle-progress-text {
  fill: #000000;
}

.yellow-circle {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  font-size: 12px;
  background-color: #e4b21b;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
