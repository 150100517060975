<template>
    <div>
      <Breadcrumb :breadcrumbList="breadcrumbList" />
      <div class="content-header px-0">
        <h1 class="title-I">Cadastrar Setores de TAGs</h1>
      </div>
      <div class="col-12 col-sm-12 p-0 col-md-12">
        <div class="card direct-chat direct-chat-primary">
          <div class="card-body">
            <div class="p-4 bg-white rounded">
              <div class="row mt-2">
                <TwInput label="Setor do TAG" required="false">
                  <input
                    id="sectorName"
                    v-model="new_tag_sector"
                    type="text"
                    maxlength="55"
                    class="form-control"
                    placeholder="Novo setor para cadastrar"
                    :disabled="!hasRole('ADMIN')"
                  />
                </TwInput>
                <div style="padding-top: 29px" v-can="['ADMIN']">
                  <button
                    id="addNewTagSector"
                    class="btn btn-primary px-5"
                    @click="addTagSector"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Adicionar
                  </button>
                </div>
              </div>
              <b-table
                responsive="sm"
                striped
                borderless
                hover
                class="mt-2 rounded"
                no-sort-reset
                ref="selectableTable"
                sticky-header="610px"
                show-empty
                empty-text="Nenhum registro encontrado"
                empty-filtered-text="Nenhum grupo encontrado para essa busca"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                @context-changed="changeBsTableState"
                :busy="load"
                :fields="fields"
                :items="customerTagSectors"
                @filtered="onFiltered"
              >
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '50px' : '550px' }"
                  />
                </template>
                <template v-slot:cell(actions)="data">
                  <a
                    id="excluirTagSectorModal"
                    @click="tagSectorDelete = data.item.name"
                    v-can="['ADMIN']"
                    href="#"
                    class="mr-2"
                    data-toggle="modal"
                    data-target="#ModalDeleteTagSector"
                    title="Excluir"
                  >
                    <i class="far fa-trash-alt"></i>
                  </a>
                </template>
              </b-table>
              <Modal
                id="ModalDeleteTagSector"
                @click="tagSectorDelete = data.item.name"
                title="Excluir Setor"
                v-bind:msg="
                  'Tem certeza que deseja excluir ' + tagSectorDelete + '?'
                "
                button="Excluir"
                :method="deleteTagSector"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Breadcrumb from "../../components/Breadcrumb.vue";
  import TwInput from "@/components/TwInput.vue";
  import Modal from "../../components/Modal.vue";
  import hasRole from "@/utils/hasRole";
  export default {
    name: "CustomerAddTagsSector",
    data: function () {
      return {
        breadcrumbList: [],
        new_tag_sector: "",
        filter: null,
        perPage: 30,
        sortBy: "name",
        currentPage: 1,
        rows: 1,
        tagSectorDelete: "",
        fields: [
          { key: "name", label: "Setor" },
          { key: "actions", label: "Ações", class: "actions" },
        ],
      };
    },
    created() {
      this.$store.dispatch("tags/getSectorTags", {
          customer_id: this.customer.id,
      });
      this.updateList();
    },
    watch: {
      $route() {
        this.updateList();
      },
      customer() {
        if (this.customer) {
          this.$store.dispatch("tags/getCustomerTags", {
            customer_id: this.customer.id,
          });
        }
      },
    },
    methods: {
      hasRole,
      changeBsTableState(table_state) {
        if (this.$options.name) {
          localStorage.setItem(
            `bstable-state-${this.$options.name}`,
            JSON.stringify(table_state)
          );
        }
      },
      onFiltered(groups) {
        this.rows = groups.length;
      },
      updateList() {
        this.breadcrumbList = this.$route.meta.breadcrumb;
        if (this.customer) {
          this.breadcrumbList[1].label = this.customer.company_name;
        }
      },
      addTagSector() {
      if (this.new_tag_sector.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Setor do TAG" });
    } else if (!/^[a-zA-Z0-9\s\-_]+$/.test(this.new_tag_sector)) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Setor do TAG",
          extra:
            "Apenas letras, números, espaços, hífen e underline são permitidos",
        });
      } else if (
        this.customerTagSectors.some(
          (sector) => sector.name === this.new_tag_sector.toUpperCase()
        )
      ) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "Setor do TAG" });
      } else {
        this.$store.dispatch("tags/addTagsSector", {
          customer_id: this.customer.id,
          tag_sector_name: this.new_tag_sector,
        });
        this.updateList();
        this.new_tag_sector = "";  
    }
},
      deleteTagSector() {
        event.preventDefault();
        this.$store.dispatch("tags/deleteTagsSector", {
          customer_id: this.customer.id,
          tag_sector_name: this.tagSectorDelete,
        });
      },
    },
    components: {
      Breadcrumb,
      Modal,
      TwInput,
    },
    computed: {
      load() {
        return this.$store.getters["load"];
      },
      customer() {
        return this.$store.getters["customer/customer"];
      },
      employeeTags() {
        return this.$store.getters["tags/employeeTags"];
      },
      customerTags() {
        return this.$store.getters["tags/customerTags"];
      },
      loadingCustomerTags() {
        return this.$store.getters["tags/loadingCustomerTags"];
      },
      customerTagSectors(){
        return this.$store.getters["tags/customerTagSectors"]
      }
    },
  };
  </script>