<template>
    <div>
      <Breadcrumb :breadcrumbList="breadcrumbList" />
  
      <div class="content-header px-0">
        <h1 class="title-I">Adicionar Agendamento</h1>
      </div>
  
      <div class="col-12 col-sm-12 p-0 col-md-12">
        <div class="card-body">
          <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
            <div class="row mb-2">
              <div class="col-12">
                <div class="card direct-chat direct-chat-primary employee">
                  <div class="card-body custom-card overflow-visible">
                    <div class="p-4 bg-white rounded">
                      <div class="tab-content">
                        <div class="tab-pane fade show active">
                          <div class="row">
                            <div class="card-body">
                              <div class="p-4 bg-white rounded">
                                <form
                                  @submit.prevent="saveMonitoring"
                                >
                                  <div class="form-group">
                                    <label for="monitoringName"
                                      >Nome do agendamento
                                      <span class="required">*</span></label
                                    >
                                    <input
                                      id="monitoringName"
                                      v-model="monitoring.name"
                                      type="text"
                                      maxlength="64"
                                      class="form-control"
                                      placeholder="Nome do agendamento"
                                      required
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label
                                      >Período de agendamento
                                      <span class="required">*</span></label
                                    >
                                    <div class="form-row align-items-center">
                                      <div class="col-lg-1 pl-1">Inicia em</div>
                                      <div class="col-lg-3">
                                        <input
                                          v-model="startDate"
                                          class="form-control"
                                          type="date"
                                          :min="new Date().toISOString().split('T')[0]"
                                          :max="'2099-12-31'"
                                          id="startDate"
                                        />
                                      </div>
                                      <div class="col-lg-1 text-center">
                                        Entre
                                      </div>
                                      <div class="col-lg-2">
                                        <select
                                          id="startTime"
                                          class="form-control"
                                          v-model="startTime"
                                        >
                                          <option
                                            v-for="time in times"
                                            :key="time"
                                            :value="time"
                                          >
                                            {{ time }}
                                          </option>
                                        </select>
                                      </div>
                                      <div class="col-lg-1 text-center">a</div>
                                      <div class="col-lg-2">
                                        <select
                                          id="stopTime"
                                          class="form-control"
                                          v-model="stopTime"
                                        >
                                          <option
                                            v-for="time in times"
                                            :key="time"
                                            :value="time"
                                          >
                                            {{ time }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label
                                      >Repetir a cada
                                      <span class="required">*</span>
                                    </label>
  
                                    <div class="form-row align-items-center">
                                      <div class="col-lg-2">
                                        <input
                                          v-model="
                                            monitoring.interval_of_repetition
                                          "
                                          class="form-control"
                                          id="repeatInterval" 
                                          type="number"
                                          min="1"
                                          max="999"
                                          placeholder="1"
                                        />
                                      </div>
                                      <div class="col-lg-3">
                                        <select
                                          id="intervalOfRepetition"
                                          class="form-control"
                                          v-model="monitoring.type_of_interval"
                                        >
                                          <option value="null" disabled selected>
                                            Selecione
                                          </option>
                                          <option value="DIA">Dia</option>
                                          <option value="SEMANA">Semana</option>
                                          <option value="MES">Mês</option>
                                          <option value="ANO">Ano</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="form-group"
                                    v-if="
                                      monitoring.type_of_interval === 'SEMANA'
                                    "
                                  >
                                    <label>Repetir:</label>
                                    <WeekDays
                                      size="sm"
                                      :selectedDays="daysofWeek"
                                      @updateSelectedDays="toggleWeekDays"
                                    >
                                    </WeekDays>
                                  </div>
                                  <div class="form-group">
                                    <label
                                      >Encerramento
                                      <span class="required">*</span></label
                                    >
                                    <div class="form-row align-items-center">
                                      <div class="col-lg-2">
                                        <b-form-group>
                                          <b-form-radio-group
                                            v-model="selectedClosure"
                                            :options="closures"
                                            plain
                                            name="closure"
                                            id="closure"
                                          />
                                        </b-form-group>
                                        <div v-if="selectedClosure === 'No dia'">
                                          <input
                                            v-model="monitoring.stop_date"
                                            class="form-control"
                                            type="date"
                                            :min="'1900-01-01'"
                                            :max="'2099-12-31'"
                                            id="closureDate"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="col-lg-3 pr-2">
                                      <label for="numberOfPeople"
                                        >Número de pessoas na linha
                                        <span class="required">*</span></label
                                      >
                                      <input
                                        v-model="
                                          monitoring.number_of_tags_expected
                                        "
                                        min="1"
                                        max="999"
                                        class="form-control"
                                        type="number"
                                        placeholder="Número de pessoas na linha"
                                        id="numberOfPeople"
                                      />
                                    </div>
                                    <div class="col-lg-3 pr-2">
                                      <label for="monitoringNotificationGroup"
                                        >Grupo de Notificação
                                        <span class="required">*</span>
                                      </label>
                                      <input
                                        v-model="monitoring.notification_group"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        max="20"
                                        placeholder="Grupo de Notificação"
                                        id="monitoringNotificationGroup"
                                      />
                                    </div>
                                    <div class="col-lg-3 pr-2">
                                      <label for="monitoringNotificationGroup"
                                        >Tempo para alerta inicial (min)
                                        <span class="required">*</span>
                                      </label>
                                      <input
                                        v-model="monitoring.notification_threshold"
                                        class="form-control"
                                        type="number"
                                        max="15"
                                        min="1"
                                        placeholder="Tempo para alerta inicial (m)"
                                        id="monitoringNotificationThreshold"
                                      />
                                    </div>
                                    <div class="col-lg-3 pr-2">
                                      <label for="numberOfPeople"
                                        >Intervalo de Notificação (min)
                                        <span class="required">*</span>
                                      </label>
                                      <input
                                        v-model="monitoring.notification_interval"
                                        class="form-control"
                                        type="number"
                                        min="1"
                                        max="1440"
                                        placeholder="Intervalo de Notificação (min)"
                                        id="monitoringNotificationInterval"
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <button
                                      id="saveMonitoring"
                                      class="btn btn-primary"
                                      :disabled="load"
                                    >
                                      <i
                                        v-if="load"
                                        class="fas fa-sync-alt fa-spin"
                                      ></i>
                                      Salvar
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Breadcrumb from "../../components/Breadcrumb.vue";
  import WeekDays from "../../components/WeekDays.vue";
  import { formatDateAPI } from "../../utils/format-fields";
  import TwInput from "../../components/TwInput.vue";
  
  export default {
    name: "AddMonitoring",
    data() {
      return {
        monitoring: {
          duration: null,
          interval_of_repetition: null,
          name: "",
          number_of_tags_expected: null,
          start_date: "",
          stop_date: null,
          type_of_interval: "",
          week_days: [],
          notification_threshold: null,
          notification_interval: null,
          notification_group: null,
        },
        selectedClosure: "No dia",
        closureDate: null,
        startTime: null,
        stopTime: null,
        selectedDays: "",
        daysofWeek: [],
        breadcrumbList: [],
        closures: [
          { text: "Nunca", value: "Nunca" },
          { text: "No dia", value: "No dia" },
        ],
        times: this.generateTimes(),
        startDate: "",
      };
    },
    mounted() {
      this.updateList();
    },
  
    watch: {
      $route() {
        this.updateList();
      },
    },
  
    methods: {
      saveMonitoring() {
        if (this.isValid()) {
          this.monitoring.duration = this.calculateDuration();
          this.monitoring.week_days =
            this.daysofWeek.length > 0 ? this.daysofWeek : null;
  
          if (this.monitoring.week_days){
            this.monitoring.week_days = this.monitoring.week_days.sort((a, b) => a-b)
          }
  
          if (this.selectedClosure === "Nunca") {
            this.monitoring.stop_date = null
          }
          
          this.monitoring.start_date = `${formatDateAPI(this.startDate)} ${formatDateAPI(this.startTime) + ":00"}`;
          this.$store.dispatch("monitoring/createMonitoring", {
            customer_id: this.$route.params.customerId,
            monitoring: {...this.monitoring,
              name: this.monitoring.name.toUpperCase(),
            },
          });
        }
      },
  
      isValid() {
        this.monitoring.name = this.monitoring.name.trim();
        if (!this.monitoring.name) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome do Agendamento" });
          return false;
        } 
  
        else if (this.monitoring.name.length > 64) {
          window.frontMsg("FRNT_INVALID_FIELD_WARNING", { 
            field: "Nome do Agendamento",
            extra: "Coloque um nome de até 64 caracteres."
           });
          return false;
        } 
        
        else if (!this.startDate) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Data do Agendamento" });
          return false;
        } 
        
        else if (!this.startTime) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Hora de Início" });
          return false;
        } 
  
        else if (!this.stopTime) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Hora de Término" });
          return false;
        } 
  
        else if (new Date(`${this.startDate}T${this.startTime}`) < new Date()){
          window.frontMsg("FRNT_INVALID_FIELD_WARNING", { 
            field: "Data do Agendamento",
            extra: "Data de início deve ser igual ou posterior ao dia atual"
          });
          return false;
        } 
  
        else if (!this.monitoring.interval_of_repetition.toString()) {
          window.frontMsg("FRNT_EMPTY_WARNING", {
            field: "Repetir a cada",
          });
          return false;
        } 
        
        else if (this.monitoring.interval_of_repetition < 1 || this.monitoring.interval_of_repetition > 999) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", { 
            field: "Repetir a Cada",
            numbercondition: "entre 1 a 999"
          });
          return false;
        } 
  
        else if (!this.monitoring.type_of_interval) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Tipo de Intervalo" });
          return false;
        } 
  
        else if (this.selectedClosure === "No dia" && !this.monitoring.stop_date){
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Data de Término" });
          return false;
        }
  
        else if (this.monitoring.stop_date && new Date(this.startDate) > new Date(this.monitoring.stop_date)){
          window.frontMsg("FRNT_INVALID_FIELD_WARNING", { 
            field: "Data do Agendamento",
            extra: "Data de fim deve ser igual ou posterior à data de início"
          });
          return false;
        } 
      
        else if (!this.monitoring.number_of_tags_expected) {
          window.frontMsg("FRNT_EMPTY_WARNING", {
            field: "Número de Pessoas na Linha",
          });
          return false;
        }
  
        else if (this.monitoring.number_of_tags_expected < 0 || this.monitoring.number_of_tags_expected > 999) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", { 
            field: "Número de Pessoas na Linha",
            numbercondition: "entre 0 a 999"
          });
          return false;
        }
  
        else if (!this.monitoring.notification_group) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Grupo de Notificação" });
          return false;
        }
        
        else if (this.monitoring.notification_group < 0 || this.monitoring.notification_group > 20) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", { 
            field: "Grupo de Notificação",
            numbercondition: "entre 0 e 20"
          });
          return false;
        }
  
        else if (!this.monitoring.notification_threshold) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Tempo para Alerta Inicial" });
          return false;
        }
        
        else if (this.monitoring.notification_threshold < 0 || this.monitoring.notification_threshold > 15) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", { 
            field: "Tempo para Alerta Inicial",
            numbercondition: "entre 0 e 15"
          });
          return false;
        }
  
        else if (!this.monitoring.notification_interval) {
          window.frontMsg("FRNT_EMPTY_WARNING", { field: "Intervalo de Notificação" });
          return false;
        } 
  
        else if (this.monitoring.notification_interval < 0 || this.monitoring.notification_interval > 1440) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", { 
            field: "Intervalo de Notificação",
            numbercondition: "entre 0 e 1440"
          });
          return false;
        }
  
        return true;
      },
  
  
      updateList() {
        this.breadcrumbList = this.$route.meta.breadcrumb;
      },
      generateTimes() {
        const times = [];
        for (let i = 0; i < 24; i++) {
          for (let j = 0; j < 60; j += 30) {
            const hour = i.toString().padStart(2, "0");
            const minute = j.toString().padStart(2, "0");
            times.push(`${hour}:${minute}`);
          }
        }
        return times;
      },
  
      calculateDuration() {
        const startTimeParts = this.startTime?.split(":");
        const stopTimeParts = this.stopTime?.split(":");
        const startMinutes =
          parseInt(startTimeParts[0]) * 60 + parseInt(startTimeParts[1]);
        const stopMinutes =
          parseInt(stopTimeParts[0]) * 60 + parseInt(stopTimeParts[1]);
  
        let diffMinutes = stopMinutes - startMinutes;
        if (diffMinutes < 0) {
          diffMinutes += 24 * 60;
        }
        const hours = Math.floor(diffMinutes / 60);
        const minutes = diffMinutes % 60;
  
        return diffMinutes;
      },
  
      toggleWeekDays(newVector) {
        this.daysofWeek = newVector;
      },
    },
    components: {
      Breadcrumb,
      WeekDays,
      TwInput,
    },
    computed: {
      load() {
        return this.$store.getters["load"];
      },
    },
  };
  </script>
  
  <style>
  .adjust {
    padding: 20px;
  }
  .margin-left-zero {
    margin-left: 0;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  
  .form-row .col-lg-1,
  .form-row .col-lg-2,
  .form-row .col-lg-3,
  .form-row .col-lg-4 {
    position: relative;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .form-row .col-lg-1,
  .form-row .col-lg-2 {
    padding-right: 2.5px;
    padding-left: 2.5px;
  }
  
  .text-center {
    text-align: center;
  }
  </style>
  