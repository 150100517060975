<template>
    <div>
      <Breadcrumb :breadcrumbList="breadcrumbList" />
      <div class="content-header px-0">
        <h1 class="title-I">Associar um Setor a Tags</h1>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card direct-chat direct-chat-primary">
            <div class="card-body">
              <div class="p-4 bg-white rounded">
                <form @submit.prevent="assignSector">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="form-group required position-relative">
                        <template v-if="Object.keys(selectedSector).length === 0">
                          <template>
                            <label>
                              Buscar Setor <span class="required">*</span>
                            </label>
                            <input
                              required
                              v-model="sectorIdentification"
                              type="text"
                              class="form-control filter-input"
                              placeholder="Setor"
                              id="sectorIdentification"
                              autocomplete="off"
                            />
                          </template>
                          <div v-if="filteredSectors.length" class="autocomplete">
                            <div
                              v-for="sector in filteredSectors"
                              class="sector"
                              :key="sector.id"
                              @click="selectedSector = sector"
                            >
                              <b>{{ sector.name }}</b>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <label>
                            Setor Selecionado <span class="required">*</span>
                          </label>
                          <div
                            @click="selectedSector = {}"
                            class="sector selected-sector"
                            type="button"
                          >
                            <b>{{ selectedSector.name }}</b>
                          </div>
                          <small>Clique no setor para alterar</small>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-2" style="padding-top: 29px">
                      <button
                        id="assignSector"
                        class="btn btn-primary float-right"
                        type="submit"
                        :disabled="load || tagAssignedSector.length > 0"
                        
                      >
                        <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                        Associar
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <b-table
                        responsive="sm"
                        striped
                        borderless
                        :busy="load"
                        :items="tagAssignedSector"
                        @context-changed="changeBsTableState"
                        :fields="fields"
                        show-empty
                        ref="sectortable"
                        id="sectorTable"
                      >
                        <template v-slot:table-busy>
                          <div class="text-center my-2">
                            <b-spinner class="align-middle mr-3"></b-spinner>
                            <strong>Carregando...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                          <button
                            type="button"
                            @click="unassignSector(data.item)"
                            class="mr-3"
                            id="tagButton"
                          >
                            <i
                              class="fas fa-lg fa-user-minus"
                              title="Dessasociar Setor"
                            ></i>
                          </button>
                        </template>
                        <template slot="empty">Esta tag ainda não foi associada com nenhum setor</template>
                      </b-table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Breadcrumb from "../../components/Breadcrumb.vue";
  import hasRole from "../../utils/hasRole";
  export default {
    name: "AddSectorTags",
    data: function () {
      return {
        breadcrumbList: [],
        fields: [
          { label: "Setor", key: "name" },
          { label: "Ações", key: "actions", class: "actions" },
        ],
        sectorIdentification: "",
        selectedSector: {},
        tagAssignedSector: [],
      };
    },
    components: {
      Breadcrumb,
    },
    watch: {
      $route() {
        this.updateList();
      },
      filterType() {
        this.clearSearchInputs();
      },
      tagSectorOperated(){
        this.tagAssignedSector = this.tagSectorOperated;
      },
    },
    computed: {
      customer() {
        return this.$store.getters["customer/customer"];
      },
      customerTags() {
        return this.$store.getters["tags/customerTags"];
      },
      filteredSectors() {
        if (this.sectorIdentification) {
          return this.customerTagSectors.filter((sector) => {
            return (sector.name.toLowerCase().includes(this.sectorIdentification.toLowerCase()));
          });
        }
        return this.customerTagSectors.filter((sector) => !sector.used);
      },
      customerTagSectors(){
        return this.$store.getters["tags/customerTagSectors"]
      },
      loadingAssign() {
        return this.$store.getters["tags/loadingAssign"];
      },
      load() {
        return this.$store.getters["load"];
      },
      tagSectorOperated() {
          return this.$store.getters["tags/tagSector"];
      }
    },
    mounted() {
      if (this.$route.params.customerId) {
        this.$store.dispatch("customer/getCustomer", {
          customerId: this.$route.params.customerId,
        });
        this.$store.dispatch("tags/getCustomerTags", {
          customer_id: this.$route.params.customerId,
        });
        this.$store.dispatch("tags/getSectorTags", {
          customer_id: this.$route.params.customerId,
        });
      } else {
        window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
        this.$router.push("/");
      }
      if(this.$route.params.tagSector){
        this.tagAssignedSector = [{name: this.$route.params.tagSector}];
      }
      this.updateList();
    },
    methods: {
      updateList() {
        this.breadcrumbList = this.$route.meta.breadcrumb;
        if (this.customer) {
          this.breadcrumbList[1].label = `${this.customer.company_name}`;
        }
        if (!hasRole(["ADMIN", "SYSADMIN"])) {
          this.breadcrumbList[2].name = null;
          this.breadcrumbList[2].link = null;
        }
        this.breadcrumbList[2].label = "Associar Tags";
      },
      changeBsTableState(table_state) {
        if (this.$options.name) {
          localStorage.setItem(
            `bstable-state-${this.$options.name}`,
            JSON.stringify(table_state)
          );
        }
      },
      async assignSector() {
        const data = {
          name: this.selectedSector.name,
          tagId: this.$route.params.tagId,
          customerId: this.$route.params.customerId,
        };
        if (this.selectedSector.name) {
          await this.$store.dispatch("tags/assignSectorToTag", data);
          this.selectedSector = {};
          this.sectorIdentification = "";
          this.updateList();
        } else {
          window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
            field: "Setor",
            extra: "Selecione um Setor para associar",
          });
        }
      },
      unassignSector(sector) {
        const data = {
          name: sector.name,
          tagId: this.$route.params.tagId,
          customerId: this.$route.params.customerId,
        };
        this.$store.dispatch("tags/unassignSectorFromTag", data);
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .filter-input {
    &:focus,
    &:active {
      + .autocomplete {
        display: block;
      }
    }
  }
  .autocomplete {
    border: 1px solid #ced4da;
    max-height: 200px;
    position: absolute;
    width: 100%;
    z-index: 2;
    background: white;
    border-radius: 4px;
    overflow-y: auto;
    display: none;
    &:hover,
    &:active {
      display: block;
    }
  }
  .tag {
    padding: 4px;
    font-size: 0.75rem;
    &.selected-tag {
      text-align: left;
      border-radius: 4px;
      display: block;
      width: 100%;
      line-height: 1;
      padding: 5px;
      border: 1px solid #ced4da;
      > div {
        margin-top: 2px;
      }
    }
    &:hover {
      cursor: pointer;
      background: #f4f6f9;
    }
    div {
      span {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
  </style>