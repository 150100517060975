<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Notificações</h1>
      {{ load ? "carregando" : "" }}
    </div>

    <div class="form-inline col-12 p-0">
  <div class="pl-0 col-6 d-flex align-items-center p-0">
    <b-input-group class="w-100">
      <b-input-group-prepend is-text>
        <i class="fas fa-search"></i>
      </b-input-group-prepend>
      <input
        v-model="filter"
        type="search"
        class="form-control"
        id="filterInput"
        placeholder="Buscar"
      />
    </b-input-group>

    <b-dropdown
      id="classDropdown" 
      variant="light" 
      class="ml-1"
      :text="selectedClass ? `${selectedClass}` : 'Filtrar Classe'"
      :class="{'has-class-selected': selectedClass}"
    >
      <b-dropdown-item
        v-for="(classe, index) in availableClasses"
        :key="index"
        @click="applyClassFilter(classe)"
      >
        {{ classe }}
      </b-dropdown-item>
      <b-dropdown-item @click="applyClassFilter('')">Mostrar Todos</b-dropdown-item>
    </b-dropdown>
  </div>

  <div class="col-4 col-md-2 p-0" style="position: absolute; right: 0; top: 0;">
    <router-link
      id="add-notification"
      title="Adicionar Notificações"
      v-can="['ADMIN']"
      to="/notification/add"
      class="btn btn-primary w-100"
    >
      <span class="d-none d-md-block d-lg-block">Adicionar</span>
      <span class="d-block d-md-none d-lg-none">
        <i class="fas fa-plus-circle"></i>
      </span>
    </router-link>
  </div>
</div>
    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhuma notificação encontrada para essa busca"
      show-empty
      :filter="filter"
      :busy="load"
      :fields="fields"
      :sort-by.sync="sortBy"
      :items="filteredAlerts"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
    >
    <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{ width: '550px' }"
        />
      </template>

      <template v-slot:cell(actions)="data">

        <a
          v-can="['ADMIN']"
          @click="notificationDelete = data.item"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalDeleteNotification"
          title="Excluir"
          :id="'excluir'"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>

        <router-link
          id="editNotification"
          v-can="['ADMIN']"
          :to="{
            name: 'notification-edit',
            params: {notificationId: data.item.code },
          }"
          title="Editar"
        >
          <i class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
    </b-table>
    <Modal
      id="ModalDeleteNotification"
      @click="notificationDelete = data.item.code"
      title="Excluir Notificações"
      :msg="'Tem certeza que deseja excluir a  notificação com o código ' + notificationDelete.code + ' ?'"
      style="white-space: pre-wrap"
      button="Excluir"
      :method="deleteNotification"
    />

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            id="linhasDropdown"
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 1000;
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import { BDropdown, BDropdownItem, BModal } from "bootstrap-vue";

export default {
  name: "ManagerNotification",
  components: {
    TwInput,
    Breadcrumb,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  data() {
    return {
      filter: null,
      selectedClass: null,
      perPage: 10,
      rows: 1,
      sortBy: "id",
      notificationDelete: "",
      currentPage: 1,
      fields: [
        { key: "class_", sortable: true, label: "Classe" },
        { key: "code", sortable: true, label: "Código da Mensagem" },
        { key: "msg", sortable: true, label: "Mensagem" },
        { key: "actions", sortable: false, label: "Ações", class: "actions" },
      ],
    };
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    availableClasses() {
      const classes = this.alerts.map((alert) => alert.class_);
      return Array.from(new Set(classes)); // Retorna classes únicas
    },
    alerts() {
      return this.$store.getters["alert/alerts"];
    },
    
    filteredAlerts() {
      return this.alerts.filter((alert) => {
      const matchesClass =
      !this.selectedClass || alert.class_?.toLowerCase() === this.selectedClass.toLowerCase();
      const matchesSearch =
      !this.filter ||
      Object.values(alert)
        .join(" ")
        .toLowerCase()
        .includes(this.filter.toLowerCase());
    return matchesClass && matchesSearch;
  });
}

  },

  watch: {
    alerts() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );

        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.alerts.length;
       } else {
          if (ctx.perPage) {
            this.perPage = ctx.perPage;
          }
          if (ctx.sortBy !== "") {
            this.sortBy = ctx.sortBy;
          }
        }
      }
    },
  },

  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    applyClassFilter(className) {
      this.selectedClass = className;
},

    onFiltered(alerts) {
      this.rows = alerts.length;
    },
    deleteNotification() {
      this.$store.dispatch("alert/deleteAlert", this.notificationDelete.id);
      this.$store.dispatch("alert/get");
    },
  },
  mounted() {
    this.updateList();
    this.$store.dispatch("alert/get");
  },
};
</script>

<style>
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-user {
  display: flex;
  justify-content: center;
}

</style>
